<template>
  <div>
    <div class="my-5 pt-sm-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="mb-5 text-center">
              <img src="@/assets/images/logo.svg" alt="logo" height="50" />
            </div>

            <div class="container">
              <div class="row">
                <div class="col-sm-10 offset-sm-1 col-xs-12 text-center">
                  <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <p>Redirecting</p>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";

export default {
  name: "answer",
  data() {
    return { questions: [], controlOnStart: true };
  },
  created() {
    this.saveAnswer();
  },

  methods: {
    saveAnswer() {
      let answer = 1;
      switch (this.$route.params.answer) {
        case "accept":
          answer = 2;
          break;
        case "maybe":
          answer = 3;
          break;
        case "reject":
          answer = 4;
          break;
      }
      HTTP.patch("invitations/" + this.$route.params.id, {
        status: answer,
      }).then(() => {
        Swal.fire({
          icon: "success",
          text: "Invitation answer successfuly saved! Thank you!",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        setTimeout(() => {
          window.location = "https://galifopartners.com/";
        }, 5000);
      });
    },
  },
};
</script>